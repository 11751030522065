import React, {useMemo} from 'react';
import {EtroGroup, EtroStack, EtroTitle} from '~/components';
import {UpdatesList} from './UpdatesList';
import {DiscordInviteButton} from '~/components/DiscordButton/DiscordInviteButton';
import {NitroPayAd} from '~/components/NitroPayAd';
import {Update} from '~/types';
import {useMantineTheme} from '@mantine/core';
import {useMobileMediaQuery} from '~/hooks';
import {useTranslation} from 'react-i18next';
const currentVersion = require('~/constants/version');

export const getUpdateList = (): Update[] => {
  return [
    // {versionNumber: currentVersion, announcement: null, updates: []},
    {
      versionNumber: currentVersion,
      announcement: null,
      updates: ['Added 7.15 Items.']
    },
    {
      versionNumber: 'v20241127.1',
      announcement: null,
      updates: [
        'Added 7.11 Items.',
        'Fixed an issue where the meld caps for Everseeker gear were incorrect.'
      ]
    },
    {
      versionNumber: 'v20241123.1',
      announcement: null,
      updates: ['Added 7.1 Items.']
    },
    {
      versionNumber: 'v20240730.1',
      announcement: null,
      updates: [
        'Added 7.05 Items.',
        'Added XivGear gearset importing.',
        'Adjusted default item level ranges.',
        'Temporarily disabled Lodestone gearset importing due to server instability.',
        'Fixed imported gearsets not having totalParams calculated.',
        'Fixed an issue with item level sync for VPR and PCT.',
        'API: Fixed an issue with BLU using physical weapon damage.'
      ]
    },
    {
      versionNumber: 'v20240720.1',
      announcement: null,
      updates: ['Added 7.01 Items. (Sorry for delay!)']
    },
    {
      versionNumber: 'v20240716.1',
      announcement: null,
      updates: [
        'Updated Main stat tiering and multiplier for level 100.',
        'Updated base HP and scalar for level 100.',
        'Updated Tenacity damage and mitigation formulas.',
        'Updated PCT and VPR icons to match the other jobs.',
        'Removed Dawntrail spoiler and formula warning.'
      ]
    },
    {
      versionNumber: 'v20240626.2',
      announcement: null,
      updates: [
        'Added Dawntrail (7.0) Items.',
        'Added support for PCT and VPR.',
        'Adjusted default level, materia and item level ranges.',
        'Account override for item level range was reset to DT values.',
        'Medicine is now sorted by descending item level.',
        'Removed Astrodyne from AST stats.'
      ]
    },
    {
      versionNumber: 'v20240116.1',
      announcement: null,
      updates: [
        'Added 6.55 Items.',
        'Added support for Mandervillous customization.'
      ]
    },
    {
      versionNumber: 'v20231230.1',
      announcement: null,
      updates: [
        'Adjusted minimum food item level for battle jobs to 370.',
        'Fixes for server stability.'
      ]
    }
  ];
};

export const Updates: React.FC = () => {
  const updateList = useMemo(() => getUpdateList(), []);
  const {t} = useTranslation();
  const {breakpoints} = useMantineTheme();
  const isMobile = useMobileMediaQuery();

  return (
    <>
      <EtroGroup
        align={'flex-start'}
        noWrap
        position="center"
        spacing={isMobile ? 0 : 'xl'}
      >
        <EtroStack align={'center'} spacing={'xl'} sx={{flexGrow: 1}}>
          <NitroPayAd placementId="etro-ad-changelog-top" type="bannerLarge" />
          <EtroTitle color="etro" order={1} align="center">
            {t('changelog')}
          </EtroTitle>
          <EtroStack spacing={'xl'} sx={{flex: 1, maxWidth: breakpoints.xs}}>
            {updateList.map((x, i) => (
              <UpdatesList {...x} key={`update-list-${i}`} />
            ))}
            <DiscordInviteButton
              text={t('view full changelog')}
              className={'etro-discord-button'}
              outlined
            />
          </EtroStack>
          <NitroPayAd
            placementId="etro-ad-changelog-bottom"
            type="bannerMed"
            config={{renderVisibleOnly: true}}
          />
        </EtroStack>
        <NitroPayAd placementId="etro-ad-changelog-right" type="anchor" />
      </EtroGroup>
      <NitroPayAd placementId="etro-ad-changelog-floating" type="floating" />
    </>
  );
};
